<template>
  <div class="dot-plot-container" :class="[reportFullscreen.status?'change-height':'', isCollapsed & this.reportFullscreen.status ? 'change-width': '']">
    <div class="header">
      <div>
      Emerging Critical Priorities
      <img src="../../assets/info-new.svg" alt="" class="pl-2" title="" @mouseenter="tooltip($event, dotPlotTooltip, 'in')" @mouseleave="tooltip($event, dotPlotTooltip, 'out')" @mousemove="tooltip($event, dotPlotTooltip, 'in')">
      </div>
      <b-button class="toggle-button" @click="toggleReportFullscreen(id)">
        <div>
        <svg xmlns="http://www.w3.org/2000/svg" class="expand-icon" width="16" height="16" fill="#c2c7de" viewBox="0 0 16 16" v-if="!fullscreen" v-bind:class="'icons'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.328 10.181H0V16h5.819v-2.328H2.328zM0 5.819h2.328V2.328h3.491V0H0zM10.181 0v2.328h3.491v3.491H16V0zM13.672 13.672h-3.491V16H16v-5.819h-2.328z" class="cls-1"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="#c2c7de" width="16" height="16" viewBox="0 0 16 16" v-else="" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.491 16h2.328v-5.819H0v2.328h3.491zM5.819 0H3.491v3.491H0v2.328h5.819zM16 5.819V3.491h-3.491V0h-2.328v5.819zM12.509 12.509H16v-2.328h-5.819V16h2.328z" class="cls-1"/></svg>
        </div>
      </b-button>
    </div>
    <div class="dot-plot-legend">
      <div class="legend">
        <span class="colored-rect" style="background: #C7C7C7"></span>
        <p>Previous Period</p>
      </div>
      <div class="legend">
        <span class="colored-rect" style="background: var(--green)"></span>
        <p>Current Trending</p>
      </div>
      <div class="legend">
        <span class="colored-rect" style="background: var(--inava-pink)"></span>
        <p>Current Declining</p>
      </div>
    </div>
    <svg id="dot-plot"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { mapActions, mapState } from 'vuex'
import tooltip from '../../util/tooltip'
/**
 * The Horizontal Dot Plot component. It is used on the Trending and Declining Drivers page. It shows the percentage change in the topic-wise share of comments for a selected time period with comparison to the previous range of the same duration.
 */
export default {
  name: 'HorizontalDotPlot',
  data () {
    return {
      dotPlotVerticalMargins: null,
      zoomVal: 1,
      id: 1,
      observer: null,
      yOffset: 9.1,
      // textOffset should always be 1/4th of maxZoom
      maxZoom: 8,
      dotPlotTooltip: 'Indicates change in volume for core capabilities across the chosen periods'
    }
  },
  computed: {
    ...mapState('sunriseSunset', ['jsonData', 'selectedSSTopic']), // selectedVoeSSTopic
    ...mapState('common', ['isCollapsed', 'reportFullscreen', 'currentModule']),
    zoomValueDisplay () {
      return this.zoomVal
    },
    fullscreen () {
      return (this.reportFullscreen.id === this.id && this.reportFullscreen.status)
    },
    textOffset () {
      return -0.25 * this.maxZoom
    }
    // selectedTopic () {
    //   return this.currentModule === 'voc' ? this.selectedSSTopic : this.selectedVoeSSTopic
    // }
  },
  watch: {
    jsonData () {
      this.renderChart()
    }
  },
  mounted () {
    this.resizeWindow()
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    ...mapActions('sunriseSunset', [
      'getData',
      'updateSelectedSSTopic',
      'updateIsSSTStandard',
      'updateSelectedAttribute'
      // 'updateSelectedVoeSSTopic',
      // 'updateSelectedVoeAttribute'
    ]),
    ...mapActions('common', ['toggleReportFullscreen']),
    tooltip,
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (this.jsonData.length) {
          if (d3.select('.dot-plot-container').node() != null) {
            // console.log('Resizing')
            this.renderChart()
          }
        }
      })
      this.observer.observe(document.querySelector('.dot-plot-container'))
    },
    // updateChartHeight () {
    //   const innerContainer = document.querySelector('.dot-plot-container')
    //   const outerContainer = document.querySelector('.report-inside')
    //   innerContainer.style.height = `${55 * this.jsonData.length + this.dotPlotVerticalMargins}px`
    //   outerContainer.style.height = `${55 * this.jsonData.length + this.dotPlotVerticalMargins + 80}px`

    //   this.renderChart()
    // },
    tooltipFun (event, d, type) {
      let data = {}
      switch (type) {
        case 'in':
          data = {
            '# of comments (current period)': `${d.currentCount}/${d.currentTotal}`,
            '# of comments (previous period)': `${d.prevCount}/${d.prevTotal}`,
            'Change in share': Math.round((d.max - d.min) * 100) / 100 + '%'
          }
          break
      }
      tooltip(event, data, type)
    },
    /**
     * This method renders the Dot Plot
     * @public
     */
    renderChart () {
      // Helper variables
      const yOffset = this.yOffset
      const textOffset = this.textOffset
      const maxZoom = this.maxZoom
      const that = this

      // Chart code
      d3.selectAll('#dot-plot > *').remove()
      const margin = {
        top: 10,
        right: 20,
        bottom: 30,
        left: 140
      }
      this.dotPlotVerticalMargins = margin.top + margin.bottom

      const innerContainer = document.querySelector('.dot-plot-container')
      if (!this.reportFullscreen.status) {
        innerContainer.style.height = `${55 * this.jsonData.length + this.dotPlotVerticalMargins}px`
      } else if (this.reportFullscreen.status & this.jsonData.length < 12) {
        innerContainer.style.height = 'calc(100vh - 60px)'
      }

      const width =
        d3.select('.dot-plot-container').node().getBoundingClientRect().width - 10 -
        margin.left -
        margin.right
      const height =
        d3.select('.dot-plot-container').node().getBoundingClientRect().height -
        margin.top -
        margin.bottom - 85
      // console.log('height', height)

      const svg = d3
        .select('#dot-plot')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      const zoomContainer = svg.append('rect')
        .attr('width', width)
        .attr('height', height)
        .attr('fill', 'transparent')
        .style('pointer-events', 'all')
        .attr('transfrom', 'translate(' + margin.left + ',' + margin.top + ')')

      const json = this.jsonData
      function callFunction (data) {
        // Add X axis
        // const x = d3.scaleLinear().domain([0, (d3.max([...json.map(a => a.max), ...json.map(a => a.min)]))]).range([0, width])
        const x = d3.scaleLinear().domain([-10, 110]).range([0, width])
        const y = d3.scaleBand().range([0, height]).domain(data.map(function (d) { return d.name })).padding(1)

        const topLine = svg.append('g')
          .attr('transform', 'translate(0, 0)')
          .attr('class', 'x-axis top-line')
          .call(d3.axisTop(x).ticks(0).tickSize(0))
        const rightLine = svg.append('g')
          .attr('transform', 'translate(' + width + ', 0)')
          .attr('class', 'y-axis right-line')

        topLine.select('path').attr('stroke', 'var(--dot-plot-axis-color)')
        rightLine.append('path')
          .attr('d', 'M 0, 0 z')
          .attr('stroke', 'var(--dot-plot-axis-color)')

        const xAxis = svg.append('g')
          .attr('transform', 'translate(0,' + height + ')')
          .attr('class', 'x-axis')
          .call(d3.axisBottom(x).ticks(5).tickSize(-height))
        const yAxis = svg.append('g').attr('class', 'y-axis').call(d3.axisLeft(y))

        // Zoom code
        const zoom = d3.zoom()
          .scaleExtent([1, maxZoom])
          .translateExtent([
            [0, 0],
            [width, height]
          ])
          .extent([
            [0, 0],
            [width, height]
          ])
          .on('zoom', zoomed)

        zoomContainer.call(zoom)
          .on('dblclick.zoom', function (d) {
            // console.log('Entered dblclick')
            // zoom.transform(zoomContainer, d3.zoomIdentity.scale(1))
            zoomContainer.transition().duration(500).call(zoom.transform, d3.zoomIdentity)
          })

        function zoomed (event, d) {
          const t = event.transform
          this.zoomVal = t.k
          const zoomOffset = yOffset / maxZoom
          const textZoomOffset = textOffset / maxZoom
          const newX = event.transform.rescaleX(x)
          if (t.k > 1) {
            xAxis.call(d3.axisBottom(x).scale(newX).tickSize(-height))

            xAxis
              .selectAll('text')
              .attr('font-size', 12)
              .attr('font-family', 'Quicksand, sans-serif')
              .attr('fill', 'var(--dot-plot-legend-text-color)')
              .attr('fill-opacity', '0.8')

            yAxis.select('.domain')
              .attr('d', 'M 0,0')

            plotData
              .selectAll('line')
              .attr('transform', d3.zoomIdentity.translate(0, -zoomOffset * t.k))
            plotData
              .selectAll('.old-value')
              .attr('transform', d3.zoomIdentity.translate(0, -zoomOffset * t.k))
            plotData
              .selectAll('.newCircle')
              .attr('transform', d3.zoomIdentity.translate(0, -zoomOffset * t.k))
            plotData
              .selectAll('text')
              .attr('transform', d3.zoomIdentity.translate(0, textZoomOffset * t.k))

            plotData.selectAll('path').attr('transform', d3.zoomIdentity.scale(1 / t.k))
            plotData
              .selectAll('svg')
              .each(function (d, i) {
                d3.select(this)
                  .attr('x', function (d, i) {
                    if (d.min < d.max) {
                      return Number(d3.select(this.parentNode.parentNode).select('.new-value').attr('cx')) + (6 / t.k)
                    } else if (d.max < d.min) {
                      return Number(d3.select(this.parentNode.parentNode).select('.new-value').attr('cx')) - (23 / t.k)
                    }
                  })
                  .attr('y', function () {
                    return Number(d3.select(this.parentNode.parentNode).select('.new-value').attr('cy')) - (maxZoom / t.k)
                  })
              })
          } else {
            xAxis.call(d3.axisBottom(x).scale(newX).tickFormat(function (d) { if (d % 20 === 0) { return d } }))
            // xAxis.call(d3.axisBottom(x).ticks(5).tickSize(-height))
            xAxis.selectAll('.tick')
              .selectAll('line')
              .attr('y2', function (d) { if (d % 20 !== 0) { return 0 } else { return -height } })
            xAxis.selectAll('.tick text')
              .attr('y', '3')
            xAxis.select('.domain')
              .attr('d', `M 0.5 0 H ${width}`)

            yAxis.select('.domain')
              .attr('d', `M 0.5,0.5 V${height}`)

            rightLine.select('path')
              .attr('d', `M 0, 0 l 0 ${height} z`)

            plotData
              .selectAll('line')
              .attr('transform', d3.zoomIdentity.translate(0, 0))
            plotData
              .selectAll('.old-value')
              .attr('transform', d3.zoomIdentity.translate(0, 0))
            plotData
              .selectAll('.newCircle')
              .attr('transform', d3.zoomIdentity.translate(0, 0))
            plotData
              .selectAll('text')
              .attr('transform', d3.zoomIdentity.translate(0, 0))
          }

          xAxis.selectAll('line')
            .attr('stroke', 'var(--dot-plot-axis-color)')
          xAxis.selectAll('text')
            .attr('font-size', '12px')

          yAxis.attr('transform', d3.zoomIdentity.translate(0, t.y).scale(t.k))
          yAxis.selectAll('.domain')
            .attr('transform', d3.zoomIdentity.scale(1 / t.k))
          yAxis.selectAll('rect')
            .attr('transform', d3.zoomIdentity.scale(1 / t.k))
          yAxis.selectAll('text')
            .attr('transform', d3.zoomIdentity.scale(1 / t.k))
          yAxis.selectAll('line')
            .attr('transform', d3.zoomIdentity.scale(1 / t.k))

          plotData.attr('transform', t)
          plotData.selectAll('.old-value')
            .attr('r', 6 / t.k)
            .attr('stroke-width', `${2 / t.k}px`)
          plotData.selectAll('.new-value')
            .attr('r', 6 / t.k)
            .attr('stroke-width', `${2 / t.k}px`)
          plotData.selectAll('line')
            .attr('stroke-width', `${2 / t.k}px`)
          plotData.selectAll('text')
            .attr('font-size', `${12 / t.k}px`)

          plotData.selectAll('.chevrons')
            .attr('transform', d3.zoomIdentity)
        }

        xAxis
          .selectAll('line')
          .attr('stroke', 'var(--dot-plot-axis-color)')

        xAxis
          .selectAll('text')
          .attr('font-size', 12)
          .attr('font-family', 'Quicksand, sans-serif')
          .attr('fill', 'var(--dot-plot-legend-text-color)')
          .attr('fill-opacity', '0.8')

        xAxis
          .select('.domain')
          .attr('stroke', 'var(--dot-plot-axis-color)')
          .attr('stroke-opacity', '0.8')

        // Y axis
        yAxis
          .selectAll('text')
          .data(data)
          .attr('class', 'y-axis-labels')
          .attr('text-anchor', 'middle')
          .attr('x', '-71')
          .attr('fill', 'var(--dot-plot-axis-label-color)')
          .attr('font-family', 'Quicksand, sans-serif')
          .attr('font-size', 11)
          .attr('fill-opacity', '0.8')
          .call(wrap, 100)
          .on('click', (event, d) => {
            // console.log(d)
            // that.updateIsSSTStandard(d.is_standard)
            // if (that.currentModule === 'voc') {
            //   that.updateSelectedSSTopic(d)
            //   that.updateSelectedAttribute([])
            // }
            // if (that.currentModule === 'voe') {
            //   that.updateSelectedVoeSSTopic(d)
            //   that.updateSelectedVoeAttribute([])
            // }
            that.updateSelectedSSTopic(d)
            that.updateSelectedAttribute([])
          })

        yAxis
          .selectAll('.tick')
          .data(data)
          .insert('rect', 'text')
          .attr('class', 'label-container')
          .attr('x', '-128')
          // .attr('x', '-115')
          .attr('y', function (d, i) {
            const multilineLabels = document.querySelectorAll('.y-axis-labels')
            if (multilineLabels[i].childNodes.length >= 2) {
              return -20
            } else {
              return -14
            }
          })
          // .attr('width', '98')
          .attr('width', '115')
          .attr('height', function (d, i) {
            const multilineLabels = document.querySelectorAll('.y-axis-labels')
            if (multilineLabels[i].childNodes.length >= 2) {
              return '40px'
            } else {
              return '28px'
            }
          })
          // .attr('rx', '4')
          .on('click', (event, d) => {
            // that.updateIsSSTStandard(d.is_standard)
            // if (that.currentModule === 'voc') {
            //   that.updateSelectedSSTopic(d)
            //   that.updateSelectedAttribute([])
            // }
            // if (that.currentModule === 'voe') {
            //   that.updateSelectedVoeSSTopic(d)
            //   that.updateSelectedVoeAttribute([])
            // }
            that.updateSelectedSSTopic(d)
            that.updateSelectedAttribute([])
          })

        function wrap (text, width) {
          // console.log(text)
          text.each(function () {
            const text = d3.select(this)
            let words = text.text().split(/[\s+]/).reverse()
            let word
            let line = []
            let lineNumber = 0
            const lineHeight = 1.4 // ems
            const x = text.attr('x')
            const y = text.attr('y')
            const dy = text.attr('dy') ? text.attr('dy') : 0
            let tspan = text.text(null).append('tspan').attr('x', x).attr('y', y).attr('dy', dy)
            while (word = words.pop()) {
              if (word.length > 17) {
                const remainingWord = word.slice(17)
                word = word.slice(0, 17)
                if (lineNumber === 1) { word += '-' }
                words.push(remainingWord + ' ')
              }
              line.push(word)
              tspan.text(line.join(' '))
              if (tspan.node().getComputedTextLength() > width) {
                line.pop()
                tspan.text(line.join(' '))
                line = [word]
                // console.log('line', line)
                if (lineNumber < 1) {
                  tspan = text.append('tspan').attr('x', x).attr('y', y).attr('dy', ++lineNumber * lineHeight + 'em').text(word)
                } else {
                  // console.log(text._groups[0][0].childNodes[text._groups[0][0].childNodes.length - 1].textContent)
                  // if (text._groups[0][0].childNodes[text._groups[0][0].childNodes.length - 1].textContent.includes('...')) {
                  //   word = ''
                  // } else { word = '...' }
                  word = '...'
                  // console.log('Entered line 2')
                  // console.log('tspan', tspan)
                  tspan = text.append('tspan').attr('dx', '0').attr('y', y).text(word)
                  words = []
                }
              }
            }
          })
        }

        yAxis
          .selectAll('text')
          .data(data)
          .attr('y', function (d, i) {
            const multilineLabels = document.querySelectorAll('.y-axis-labels')
            if (multilineLabels[i].childNodes.length >= 2) {
              return -8
            } else {
              return 0
            }
          })

        const buttons = document.querySelectorAll('.y-axis .tick')
        buttons.forEach((button) => {
          button.onclick = function (a, b) {
            toggleActive(this)
          }
        })
        d3.selectAll('.y-axis .tick').each(function (d) {
          // console.log(d.topic_id, that.selectedSSTopic)
          if (that.selectedSSTopic && d.topic_id === that.selectedSSTopic.topic_id) {
            // console.log(this)
            // d3.select(this)
            this.classList.toggle('active')
          }
        })

        function toggleActive (button) {
          buttons.forEach((b) => {
            if (b !== button) {
              b.classList.remove('active')
            }
          })
          button.classList.toggle('active')
        }

        yAxis
          .select('.domain')
          .attr('stroke', 'var(--dot-plot-axis-color)')
          .attr('stroke-opacity', '0.8')
          .attr('d', `M 0.5,0.5 V${height}`)

        svg
          .selectAll('.tick')
          .select('line')
          .attr('stroke', 'var(--dot-plot-axis-color)')
          .attr('stroke-opacity', '0.8')

        // clip path
        const defs = svg.append('defs')

        defs
          .append('SVG:clipPath')
          .attr('id', 'clip')
          .append('SVG:rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', width)
          .attr('height', height)

        const chartBody = svg.append('g')
          .attr('clip-path', 'url(#clip)')

        // Lines
        const plotData = chartBody
          .selectAll('myline')
          .data(data)
          .enter()
          .append('g')
          .attr('class', 'plot-data')

        plotData
          .data(data)
          .append('line')
          .attr('x1', function (d) {
            return x(d.min)
          })
          .attr('x2', function (d) {
            return x(d.max)
          })
          .attr('y1', function (d) {
            return y(d.name) + yOffset
          })
          .attr('y2', function (d) {
            return y(d.name) + yOffset
          })
          .attr('stroke', '#C7C7C7')
          .attr('stroke-width', '2px')

        plotData
          .data(data)
          .append('text')
          .text(function (d) {
            // const num = ((d.max - d.min) / d.min) * 100
            const num = d.max - d.min
            const rounded = Math.round(num * 100) / 100
            return `${rounded}% `
          })
          .attr('class', 'percentage-change')
          .attr('text-anchor', 'middle')
          .attr('x', function (d) {
            const max = Math.max(x(d.min), x(d.max))
            const min = Math.min(x(d.min), x(d.max))
            return min + (max - min) / 2
          })
          .attr('y', function (d) {
            return y(d.name) - 9 + yOffset
          })
          .attr('fill', 'var(--dot-plot-line-text-color)')
          .attr('font-size', '12px')

        // Circles of variable 1
        plotData
          .data(data)
          .append('circle')
          .attr('class', 'old-value')
          .attr('cx', function (d) {
            return x(d.min)
          })
          .attr('cy', function (d) {
            return y(d.name) + yOffset
          })
          .attr('r', '6')
          .style('fill', '#C7C7C7')
          .on('mouseover', (event, d) => that.tooltipFun(event, d, 'in'))
          .on('mousemove', (event, d) => that.tooltipFun(event, d, 'in'))
          .on('mouseout', (event, d) => that.tooltipFun(event, d, 'out'))
        // .style('stroke', '#C7C7C7')
        // .style('stroke-width', '2px')
        // .style('fill', '#12151A')

        // Circles of variable 2
        const newCircle = plotData.append('g').attr('class', 'newCircle')

        newCircle
          .data(data)
          .append('circle')
          .attr('class', 'new-value')
          .attr('cx', function (d) {
            return x(d.max)
          })
          .attr('cy', function (d) {
            return y(d.name) + yOffset
          })
          .attr('r', '6')
          .style('fill', function (d) {
            if (d.max > d.min) {
              return 'var(--green)'
            } else if (d.max < d.min) {
              return 'var(--inava-pink)'
            } else {
              return 'transparent'
            }
          })
          .on('mouseover', (event, d) => that.tooltipFun(event, d, 'in'))
          .on('mousemove', (event, d) => that.tooltipFun(event, d, 'in'))
          .on('mouseout', (event, d) => that.tooltipFun(event, d, 'out'))
        // .style('stroke-width', '2px')
        // .style('stroke', function (d) {
        //   if (d.max > d.min) {
        //     return 'var(--green)'
        //   } else if (d.max < d.min) {
        //     return 'var(--inava-pink)'
        //   }
        // })

        const arrows = newCircle.data(data).append('g')
          .attr('class', 'chevrons')
        const svgArrows = arrows.append('svg')
          .attr('x', function (d) {
            if (d.max > d.min) {
              return x(d.max) + 6
            } else {
              return x(d.max) - 23
            }
          })
          .attr('y', function (d) {
            return y(d.name) + 1.5
          })

        svgArrows
          .append('path')
          .attr('d', function (d) {
            if (d.max > d.min) {
              return 'M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z'
            } else if (d.max < d.min) {
              return 'M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'
            }
          })
          .style('fill', function (d) {
            if (d.max > d.min) {
              return 'var(--green)'
            } else {
              return 'var(--inava-pink)'
            }
          })

        svgArrows
          .append('path')
          .attr('d', function (d) {
            if (d.max > d.min) {
              return 'M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z'
            } else if (d.max < d.min) {
              return 'M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'
            }
          })
          .style('fill', function (d) {
            if (d.max > d.min) {
              return 'var(--green)'
            } else {
              return 'var(--inava-pink)'
            }
          })
      }

      callFunction(json)
    }
  }
}
</script>

<style lang="scss" scoped>
.dot-plot-container {
  height: 420px;
  width: 100%;
  min-height: 420px;
  transition: width 0.3s ease-out;
  background: var(--primary);
  border-radius: 4px;
  padding: 5px;
  .dot-plot-legend {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0px;
    .legend {
      display: inline-flex;
      margin-right: 20px;
      color: var(--dot-plot-legend-text-color);
      font-size: 12px;
      font-family: Quicksand;
      .colored-rect {
        display: inline-flex;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-right: 10px;
        align-self: center;
      }
    }
  }
  .header {
    color: var(--trending-declining-header);
    opacity: 1;
    font-size: 18px;
    flex: 1;
    font-weight: 500;
    font-family: Quicksand;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    padding: 0px 10px;
    // padding-left: 22px;
    // padding-top: 15px;
    // padding-bottom: 5px;

    svg {
      margin-left: 6px;
    }
  }
}

::v-deep .y-axis .tick:hover {
  cursor: pointer;
  text {
    fill: var(--hover-dot-plot-label-text);
  }
}

::v-deep rect.label-container {
  fill: transparent;
  &:hover {
    fill: var(--dot-plot-label-container);
  }
}

::v-deep .active {
  rect.label-container {
    fill: var(--dot-plot-label-container);
  }
  // text {
  //   fill: var(--secondary-text-color);
  // }
}

.toggle-button {
      // margin-left: auto;
      // margin-right: 20px;
      background-color: transparent;
      outline: none;
      border: none;
      padding: 2px 3px;
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
      }
}
.a {
  fill: none;
}
.b {
  fill: #c2c7de;
}

.change-height {
  height: calc(100vh - 60px);
  width: calc(100vw - 269px);
}

.change-width {
  width: calc(100vw - 104px)
}

@media screen and (min-width: 1700px) {
  .change-height {
    width: calc(100vw - 285px);
    }
  .change-width {
    width: calc(100vw - 120px)
  }
}
</style>
