<template>
  <div class="horizontal-group-container" :class="[reportFullscreen.status?'change-height':'', isCollapsed & this.reportFullscreen.status ? 'change-width': '']">
    <div class="header">
      <div>
      Share of Comments by {{ currentModule === 'voc' ? 'Product' : ''}} {{ currentModule === 'voe' ? 'Company' : ''}}
      </div>
      <b-button class="toggle-button" @click="toggleReportFullscreen(id)">
        <div>
        <svg xmlns="http://www.w3.org/2000/svg" class="expand-icon" width="16" height="16" fill="#c2c7de" viewBox="0 0 16 16" v-if="!fullscreen" v-bind:class="'icons'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.328 10.181H0V16h5.819v-2.328H2.328zM0 5.819h2.328V2.328h3.491V0H0zM10.181 0v2.328h3.491v3.491H16V0zM13.672 13.672h-3.491V16H16v-5.819h-2.328z" class="cls-1"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="#c2c7de" width="16" height="16" viewBox="0 0 16 16" v-else="" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.491 16h2.328v-5.819H0v2.328h3.491zM5.819 0H3.491v3.491H0v2.328h5.819zM16 5.819V3.491h-3.491V0h-2.328v5.819zM12.509 12.509H16v-2.328h-5.819V16h2.328z" class="cls-1"/></svg>
        </div>
      </b-button>
    </div>
    <div class="horizontal-group-legend">
      <div class="legend">
        <span
          class="colored-rect"
          style="background: var(--inava-primary)"></span>
        <p>Current Period</p>
      </div>
      <div class="legend">
        <span
          class="colored-rect"
          style="background: var(--horizontal-bar-chart-previous)"></span>
        <p>Previous Period</p>
      </div>
    </div>
    <svg id="horizontal-grouped-bars"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { mapState, mapActions } from 'vuex'
import tooltip from '../../util/tooltip'
import { fullScreenMode } from '../../util/util'
/**
 * This component shows the percent wise share of comments across products for a selected time period with comparison to the previous range of the same duration. Clicking on a bar will redirect the user to the *Comments* page, with filters applied to show the comments only for the selected product, in the selected time range.
 */
export default {
  name: 'CommentsShare',
  props: {
    chartData: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
      id: 3,
      observer: null
    }
  },
  computed: {
    ...mapState('sunriseSunset', ['sharePerProduct', 'voeSharePerCompany']),
    ...mapState('common', ['isCollapsed', 'reportFullscreen', 'currentModule']),
    ...mapState('filters', ['selectedProducts', 'ActiveVocTimePeriod', 'previousPeriodRange', 'selectedCompaniesVoe', 'ActiveVoeTimePeriod']),
    fullscreen () {
      return (this.reportFullscreen.id === this.id && this.reportFullscreen.status)
    },
    ActiveTimePeriod () {
      if (this.currentModule === 'voc') {
        return this.ActiveVocTimePeriod
      } else {
        return this.ActiveVoeTimePeriod
      }
    }
  },
  watch: {
    sharePerProduct () {
      this.renderChart()
    },
    voeSharePerCompany () {
      this.renderChart()
    }
  },
  mounted () {
    this.renderChart()
    this.resizeWindow()
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    ...mapActions('common', ['toggleReportFullscreen', 'resetReportFullscreen']),
    ...mapActions('filters', [
      'updateSelectedProducts',
      'updateActiveVocTimePeriod',
      'updateActiveVoeTimePeriod',
      'updateSelectedCompaniesVoe'
    ]),
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (this.chartData.length) {
          if (d3.select('.horizontal-group-container').node() != null) {
            // console.log('Resizing')
            this.renderChart()
          }
        }
      })
      this.observer.observe(document.querySelector('.horizontal-group-container'))
    },
    tooltipFun (event, d, type, module) {
      let data = {}
      const volume = d.key === 'previousShare' ? d.previousCount : d.currentCount
      switch (type) {
        case 'in':
          if (module === 'voc') {
            data = {
              'Product': d.name,
              '# of comments': volume,
              'Volume share': d.value + '%'
            }
          } else if (module === 'voe') {
            data = {
              'Company': d.name,
              '# of comments': volume,
              'Volume share': d.value + '%'
            }
          }
          break
      }
      tooltip(event, data, type)
    },
    /**
     * Globally applies the filters that are displayed by the bar graph, and redirects the user to the *Comments* page.
     * @public
     */
    showComments (d, selectedProducts) {
      if (this.reportFullscreen.status) {
        this.resetReportFullscreen()
        fullScreenMode(document, 'off')
      }
      const output = selectedProducts.filter(({ name }) => name === d.name)
      let range = []
      if (this.ActiveTimePeriod.group_by === 'quarter') {
        if (d.key === 'previousShare') {
          range = this.previousPeriodRange
        } else if (d.key === 'currentShare') {
          range = this.ActiveTimePeriod.range
        }
      } else {
        if (d.key === 'previousShare') {
          range = this.previousPeriodRange
        } else if (d.key === 'currentShare') {
          range = this.ActiveTimePeriod.range
        }
      }
      const timeObject = {}
      timeObject.group_by = this.ActiveTimePeriod.group_by
      timeObject.range = range
      if (this.currentModule === 'voc') {
        this.updateActiveVocTimePeriod(timeObject)
      } else if (this.currentModule === 'voe') {
        this.updateActiveVoeTimePeriod(timeObject)
      }
      this.tooltipFun(event, d, 'out')
      if (output.length) {
        if (this.currentModule === 'voc') {
          this.updateSelectedProducts([output[0]])
          this.$router.push({ name: 'comments' })
        }
        if (this.currentModule === 'voe') {
          this.updateSelectedCompaniesVoe([output[0]])
          this.$router.push({ name: 'voeComments' })
        }
      }
      // this.tooltipFun(event, d, 'out')
      // this.$router.push({ name: 'comments' })
    },
    /**
     * This method renders the CommentsShare plot *(Horizontal Bar Graph)*
     * @public
     */
    renderChart () {
      // make this product independent
      // const selectedProducts = this.selectedProducts
      let selectedProducts = null
      if (this.currentModule === 'voc') {
        selectedProducts = this.selectedProducts
      }
      if (this.currentModule === 'voe') {
        selectedProducts = this.selectedCompaniesVoe
      }

      const temp = []
      selectedProducts.forEach(product => {
        // const pData = this.sharePerProduct.filter(d => d.productName === product.name)[0]
        const pData = this.chartData.find(d => d.name === product.name)
        if (!pData) {
          const obj = {
            name: product.name,
            currentShare: 0,
            previousShare: 0
          }
          temp.push(obj)
        } else {
          pData.currentShare = pData.currentShare || 0
          pData.previousShare = pData.previousShare || 0
          temp.push(pData)
        }
      })

      const data = temp // this.sharePerProduct

      const margin = {
        top: 10,
        right: 5,
        bottom: 40,
        left: 165
      }
      const verticalMargins = margin.top + margin.bottom

      const innerContainer = document.querySelector('.horizontal-group-container')
      if (!this.reportFullscreen.status) {
        innerContainer.style.height = `${55 * selectedProducts.length + verticalMargins}px`
      }
      const width = d3.select('.horizontal-group-container').node().getBoundingClientRect().width
      const containerHeight = d3.select('.comments-share').node().getBoundingClientRect().height - margin.top - margin.bottom
      const height = containerHeight - 40

      const groupKey = 'name'
      const keys = ['previousShare', 'currentShare']

      const svg = d3.select('#horizontal-grouped-bars')
        .attr('width', width)
        .attr('height', height)

      d3.selectAll('#horizontal-grouped-bars > *').remove()
      const y0 = d3.scaleBand()
        // .domain(data.map(d => d[groupKey]))
        .domain(selectedProducts.map(d => d.name))
        .rangeRound([margin.top, height - margin.bottom + 30])
        .paddingInner(0.1)

      const y1 = d3.scaleBand()
        .domain(keys)
        .rangeRound([y0.bandwidth(), 0])
        .padding(0.1)

      const x = d3.scaleLinear()
        .domain([0, 100]) // d3.max(data, d => d3.max(keys, key => d[key]))]).nice()
        .rangeRound([margin.left, width - margin.right - 25])

      const color = d3.scaleOrdinal().range(['var(--horizontal-bar-chart-previous)', 'var(--inava-primary)'])

      const groups = svg.append('g')
        .selectAll('g')
        .data(data)
        .join('g')
        .attr('transform', d => `translate(0,${y0(d[groupKey])})`)

      groups
        .selectAll('rect')
        .data(d => keys.map(key => ({ key, value: d[key], name: d.name, currentCount: d.currentCount, previousCount: d.previousCount })))
        .join('rect')
        .attr('x', d => x(0))
        .attr('y', d => y1(d.key))
        .attr('height', y1.bandwidth())
        .attr('width', d => (x(d.value) - x(0) + 0.5))
        .attr('fill', d => color(d.key))
        .attr('cursor', 'pointer')
        .on('mouseover', (event, d) => this.tooltipFun(event, d, 'in', this.currentModule))
        .on('mousemove', (event, d) => this.tooltipFun(event, d, 'in', this.currentModule))
        .on('mouseout', (event, d) => this.tooltipFun(event, d, 'out'))
        .on('click', (event, d) => this.showComments(d, selectedProducts))

      groups
        .selectAll('g')
        .data(d => keys.map(key => ({ key, value: d[key] })))
        .join('text')
        .attr('x', d => x(d.value))
        .attr('y', d => y1(d.key) + y1.bandwidth() / 2)
        .attr('dx', '4')
        .attr('dy', '.35em')
        .attr('font-size', '10px')
        .attr('font-weight', 600)
        .attr('fill', 'var(--grouped-bar-chart-values-color)')
        .text(d => {
          return d.value + '%'
        })

      const xAxis = g => g
        .attr('transform', `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).tickSizeOuter(0))
        .call(g => g.select('.domain').remove())
        .call(d3.axisBottom(x).ticks(10, 's'))
        .call(g => g.select('.domain').remove())
        .call(g => g.selectAll('.tick').remove())

      const yAxis = g => g
        .attr('transform', `translate(${margin.left},0)`)
        .call(d3.axisLeft(y0).ticks(null, 's'))
        .attr('fill', 'var(--grouped-bar-chart-label-color)')
        .attr('font-size', '12px')
        .attr('font-family', 'Quicksand')
        .style('text-anchor', 'end')
        .attr('class', 'yaxis')
        .call(g => g.select('.domain').remove())
        .call(g => g.selectAll('.tick line').remove())

      svg.append('g')
        .call(xAxis)

      svg.append('g')
        .call(yAxis)

      svg.selectAll('.yaxis text')
        .call(wrap, 130)
        .attr('fill', 'var(--grouped-bar-chart-label-color)')

      function wrap (text, width, bandwidth) {
        text.each(function () {
          const textw = d3.select(this)
          const words = textw.text().split(/\s+/).reverse()
          let word
          let line = []
          let lineNumber = 0
          const lineHeight = 1.1 // ems
          const y = textw.attr('y') // - bandwidth/2,
          const dy = parseFloat(textw.attr('dy'))
          let tspan = textw
            .text(null)
            // .attr('fill', 'var(--chart-top-axis-color)')
            .attr('id', 'labels')
            .style('font-family', 'Quicksand')
            .attr('font-size', 13)
            .append('tspan')
            .attr('x', -8)
            .attr('y', y - lineHeight)
            .attr('dy', dy + 'em')
          while ((word = words.pop())) {
            line.push(word)
            tspan.text(line.join(' '))
            if (tspan.node().getComputedTextLength() > width) {
              line.pop()
              tspan.text(line.join(' '))
              line = [word]
              tspan = textw
                .append('tspan')
                .attr('x', -8)
                .attr('y', y - lineHeight)
                .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                .text(word)
            }
          }
        })
      }
      const position = [0, 5, 12, 20]
      d3.selectAll('#labels').each(function (d) {
        let count = 0
        d3.select(this).selectAll('tspan').each(function (d) {
          ++count
        })
        d3.select(this).attr('transform', `translate(0,-${position[count - 1] ? position[count - 1] : 0})`)
      })
      // chart(temp)
    }
  }
}
</script>

<style lang="scss" scoped>
.horizontal-group-container {
  transition: width 0.3s ease-out;
  height: 350px;
  min-height: 350px;
  padding: 5px;
  svg rect.bordered{
    border: 1px solid #35405299
  }
  .header {
    color: var(--trending-declining-header);
    opacity: 1;
    font-size: 18px;
    flex: 1;
    font-family: Quicksand;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    // padding-left: 22px;
    padding:0px 10px;
    // padding-bottom: 5px;
    // margin-bottom: 10px;
    .toggle-button {
      background-color: transparent;
      outline: none;
      border: none;
      padding: 2px 3px;
      display: flex;
      div {
        display: flex;
      }
    }
  }
  .horizontal-group-legend {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      // margin-top: 25px;
      .legend {
        display: inline-flex;
        margin-right: 20px;
        color: var(--trending-declining-header);
        font-size: 12px;
        font-family: Quicksand;
        .colored-rect {
          display: inline-flex;
          width: 8px;
          height: 8px;
          border-radius: 8px;
          margin-right: 10px;
          align-self: center;
        }
      }
    }
}

.horizontal-group-container.change-height {
  height: calc(100vh - 25px - var(--page-tracking-height)) !important;
  width: calc(100vw - 269px);
}

.horizontal-group-container.change-width {
  width: calc(100vw - 104px)
}

@media screen and (min-width: 1700px) {
  .horizontal-group-container.change-height {
    width: calc(100vw - 285px);
    }
  .horizontal-group-container.change-width {
    width: calc(100vw - 120px)
  }
}
</style>
