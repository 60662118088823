<template>
  <div class="slider-container">
    <img
      @click="onSlideLeft()"
      v-if="reportFullscreen.status"
      :class="['left-arrow', reportFullscreen.id <= 1 ? 'disable' : '', isCollapsed ? '' : 'left-pos']"
      src="../../assets/Arrow-down.svg" />
    <img
      v-if="reportFullscreen.status"
      @click="onSlideRight()"
      :class="['right-arrow', reportFullscreen.id >= numReports ? 'disable' : '']"
      src="../../assets/Arrow-down.svg" />
    <div ref="customScroll" :class="['report-container', reportFullscreen.status ? 'remove-flex' : '']">
    <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { fullScreenMode } from '../../util/util'

/** This component is a wrapper for all reports. It allows the reports contained within to be expanded to a fullscreen mode, and implements a slider to navigate between the different full sized reports */
export default {
  name: 'ReportWithSlider',
  // props: ['numReports'],
  props: {
    /** This prop contains the number of reports that have been wrapped around by this component. It allows it to calculate the limits of the navigation buttons */
    numReports: Number
  },
  data () {
    return {
      observer: null,
      pageFullscreeen: null
    }
  },
  computed: {
    ...mapState('common', ['isCollapsed', 'reportFullscreen'])
  },
  watch: {
    'reportFullscreen.status' () {
      const elem = document.documentElement
      if (this.reportFullscreen.status) {
        fullScreenMode(elem, 'on')
        document.querySelector('.main-view').classList.add('change-main-height')
        document.querySelector('.main-view').scroll({ top: 0 })
        this.setCurrentPage()
      } else if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement
      ) {
        fullScreenMode(document, 'off')
        this.resetReportFullscreen()
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.resizeContainer()
      this.transitionHandler()
    }, 2 * 1000)
  },
  created () {
    window.addEventListener('fullscreenchange', this.fullscreenChange)
    window.addEventListener('mozfullscreenchange', this.fullscreenChange)
    window.addEventListener('MSFullscreenChange', this.fullscreenChange)
    window.addEventListener('webkitfullscreenchange', this.fullscreenChange)
  },
  beforeDestroy () {
    window.removeEventListener('fullscreenchange', this.fullscreenChange)
    window.removeEventListener('mozfullscreenchange', this.fullscreenChange)
    window.removeEventListener('MSFullscreenChange', this.fullscreenChange)
    window.removeEventListener('webkitfullscreenchange', this.fullscreenChange)
    this.$refs.customScroll.removeEventListener('transitionstart', this.transitionHandler())
    this.$refs.customScroll.removeEventListener('transitionend', this.transitionHandler())
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    ...mapActions('common', ['nextReport', 'toggleReportFullscreen', 'resetReportFullscreen']),
    /** This method applies an event listener to the side panel. Whenever the side panel animation is triggered, it disables all animations on this component. This is to prevent the charts from shifting in place when the sidepanel is expanded/collapsed */
    transitionHandler () {
      const that = this
      const elem = document.querySelector('.flex-three')
      elem.addEventListener('transitionstart', () => {
        // console.log('Adding no-transition')
        that.$refs.customScroll.classList.add('no-transition')
        // console.log(this.$refs.customScroll.classList)
      })
      elem.addEventListener('transitionend', () => {
        setTimeout(() => {
          // console.log('Removing no-transition')
          that.$refs.customScroll.classList.remove('no-transition')
          // console.log(this.$refs.customScroll.classList)
        }, 500)
      })
    },
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeContainer () {
      this.observer = new ResizeObserver(() => {
        this.setCurrentPage()
      })
      this.observer.observe(this.$refs.customScroll)
    },
    /** This method opens up fullscreen mode and gives the slider a translate value such that the selected report is displayed to the user
     * @public
     */
    setCurrentPage () {
      this.$refs.customScroll.style.transform = `translateX(-${
        this.$refs.customScroll.clientWidth * (this.reportFullscreen.id - 1)
      }px)`
    },
    /** This method moves the viewport of the the slider to the left, bringing 'earlier/previous' reports into view
     * @public
     */
    onSlideLeft () {
      if (this.reportFullscreen.id > 1) {
        this.$refs.customScroll.style.transform = `translateX(-${
          this.$refs.customScroll.clientWidth * (this.reportFullscreen.id - 2)
        }px)`
        this.nextReport(this.reportFullscreen.id - 1)
      }
    },
    /** This method moves the viewport of the the slider to the right, bringing 'later/next' reports into view
     * @public
     */
    onSlideRight () {
      if (this.reportFullscreen.id < this.numReports) {
        this.$refs.customScroll.style.transform = `translateX(-${
          this.$refs.customScroll.clientWidth * this.reportFullscreen.id
        }px)`
        this.nextReport(this.reportFullscreen.id + 1)
      }
    },
    /** This method exits fullscreen mode as well as translate values on the slider
    * @public
    */
    fullscreenChange () {
      if (
        document.fullscreenElement === null ||
        document.webkitFullscreenElement === null ||
        document.mozFullScreenElement === null
      ) {
        this.resetReportFullscreen()
        this.$refs.customScroll.style.transform = 'translateX(0px)'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slider-container {
  position: relative;
  .left-arrow {
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
    cursor: pointer;
    position: absolute;
    position: fixed;
    top: 52.65%;
    left: 85px;
    z-index: 50;
    transition: 0.3s ease-out;
  }
  .right-arrow {
    width: 20px;
    height: 20px;
    transform: rotate(270deg);
    cursor: pointer;
    position: absolute;
    position: fixed;
    top: 52.65%;
    z-index: 50;
    right: 25px;
  }
  .disable {
    cursor: not-allowed;
    opacity: 0.2;
  }
  .left-pos {
    left: 250px;
  }
  .report-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .remove-flex {
    flex-wrap: nowrap;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
  }
  .no-transition {
    transition: none;
  }
}

@media screen and (min-width: 1700px) {
  .slider-container {
    .right-arrow {
      right: 40px;
    }
    .left-pos {
      left: 265px;
    }
  }
}
</style>
